// src/components/Home.js
import React from 'react';

function Home() {
  return <>

<main>
    <div className="mx-2 md:mx-10">

    <div 
    className="w-full rounded-lg md:h-screen h-96 flex items-center justify-center text-center bg-cover"
    // style={{ backgroundImage: "url('/images/IMG-20241001-WA0006.jpg')" }} 
     > 

    <video
        className="w-full rounded-lg h-full object-cover"
        autoPlay
        muted
        loop // Optional: to loop the video
        playsInline // Optional: for better behavior on mobile devices
      >
        <source src="/videos/eva-sunset-cabin-camp.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>


       </div>

      <section className='mx-auto'>
        <div className="md:flex justify-between items-stretch my-2 md:my-4">
          <div className="mr-0 md:mr-2 md:mb-0 w-full md:w-1/2 h-100 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp.png" alt="Eva Cabins in himanchal" layout="responsive" />
          </div>
          <div className="ml-0 md:ml-2 mt-2 md:mt-0  md:mb-0 w-full md:w-1/2 h-100 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp3.png" alt="Eva Cabins in Dalhousie"  layout="responsive"/>
          </div>
        </div>
      </section>

      <section>
        <div className="md:flex  justify-between my-2 md:my-4">
          <div className="mr-0 md:mr-2 md:mb-0 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp4.png" alt="Eva Cabins in Dalhousie" layout="responsive" />
          </div>
          <div className="ml-0 md:ml-2 mt-2 md:mt-0 md:mb-0 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp5.png" alt="Eva Cabins in Dalhousie" layout="responsive" />
          </div>
        </div>
      </section>

      <section>
        <div className="md:flex justify-between my-2 md:my-4">
          <div className="mr-0 md:mr-2 md:mb-0 w-100 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md w-full" src="/images/eva-cabins.jpg" alt="Eva Cabins in Dalhousie"  layout="responsive"/>
          </div>
          <div className="ml-0 md:ml-2 mt-2 md:mt-0 md:mb-0 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-cabins-homepage-rooms.png" alt="Khajjiar’s lake"  layout="responsive"/>
          </div>
        </div>
      </section>

      <section>
        <div className="md:flex justify-between my-2 md:my-4">
          <div className="mr-0 md:mr-2 md:mb-0 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp7.png" alt="Eva Cabins in Dalhousie"  layout="responsive"/>
          </div>
          <div className="ml-0 md:ml-2 mt-2 md:mt-0 md:mb-0 md:w-1/2 flex items-stretch">
            <img className="rounded shadow-md object-cover w-full" src="/images/eva-sunset-cabin-camp8.png" alt="Eva Cabins in Dalhousie" layout="responsive" />
          </div>
        </div>
      </section>
    </div>
      </main>
  
  
  
  
  
  
  </>;
}

export default Home;
