import React, { useEffect } from 'react';
import 'baguettebox.js/dist/baguetteBox.min.css';
import baguetteBox from 'baguettebox.js';

const images = [
  { src: '/images/eva-sunset-cabin-camp.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp2.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp3.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp4.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp5.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp6.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp10.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp11.png', caption: 'Eva cabins' },
  { src: '/images/eva-sunset-cabin-camp12.png', caption: 'Eva cabins' },
];

const Gallery = () => {
  useEffect(() => {
    baguetteBox.run('.gallery');
  }, []);

  return (
    <>
    <div className="mx-2">
    <div className="container mx-auto gallery grid grid-cols-1 md:grid-cols-3 gap-2  my-8">
      {images.map((image, index) => (
        <a  key={index} href={image.src} data-caption={image.caption}>
          <img className='rounded-lg' src={image.src} alt={`eva cabins in himachal`} />
        </a>
      ))}
    </div>
    </div>
    </>
  );
};

export default Gallery;
