import React from 'react'

// import Carousel from './imp_com/Carousel';
import Cards from './Cards';

// const slides = [
//     "/images/eva-sunset-cabin-camp13.png",
//     "/images/eva-sunset-cabin-camp15.png",
//     "/images/eva-sunset-cabin-camp16.png",
//     // "/videos/IMG-20241001-WA0001.jpg",
//     // "/videos/IMG-20241001-WA0005.jpg",
//     // "/videos/IMG-20241001-WA0006.jpg",
//   ]


const Rooms = () => {
  return (
      <>
        {/* <div className="container  m-auto pt-11">
          <Carousel slides={slides} />
        </div> */}
        <video className="container mx-auto rounded-lg w-full h-full object-cover" autoPlay muted loop playsInline>
          <source src="/videos/eva-cabins-room.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
  
        <Cards /> 
      {/* // Add this line to display the Cards component. */}
      </>
  )
}

export default Rooms
