import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const sChars = "!@#$%^&*()+=-[]\\';,./{}|\":<>?";
  const msgChars = "!#$%^&*()+=-[]\\'{}|\":<>?";

  const validateName = (value) => {
    setName(value);
    for (let char of value) {
      if (sChars.includes(char)) {
        setNameError('Please ensure to type your name using only characters; special characters are not allowed');
        setName('');
        return;
      }
    }
    setNameError('');
    validateForm();
  };

  const validateEmail = (value) => {
    setEmail(value);
    const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
    if (!regex.test(value)) {
      setEmailError(`The ${value} is not a valid email address!`);
    } else {
      setEmailError('');
    }
    validateForm();
  };

  const validateMessage = (value) => {
    setMessage(value);
    for (let char of value) {
      if (msgChars.includes(char)) {
        setMessageError('Please ensure to type your message using only characters; special characters are not allowed');
        return;
      }
    }
    setMessageError('');
    validateForm();
  };

  const validateForm = () => {
    if (name && email && !nameError && !emailError && message && !messageError) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  return (
    <main>

      <h1 className="text-center hidden">Contact Us - Deodar Manor in the Himalayas</h1>

     
      <section className="text-gray-600 body-font relative md:mx-16 mx-2">
        <div className="container px-5 py-12 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 md:p-10 flex items-end justify-start relative">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13450.242282449459!2d76.0128413!3d32.5645711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391c953760980ced%3A0x17d397207784fce0!2sEva%20sunset%20Cottage%20%26%20Camp!5e0!3m2!1sen!2sin!4v1728208785247!5m2!1sen!2sin" 
              width="100%" 
              height="100%" 
              className="md:absolute md:inset-0 block md:hidden h-96"
              style={{ border: 0 }} 
              allowFullScreen 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>     

            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13450.242282449459!2d76.0128413!3d32.5645711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391c953760980ced%3A0x17d397207784fce0!2sEva%20sunset%20Cottage%20%26%20Camp!5e0!3m2!1sen!2sin!4v1728208785247!5m2!1sen!2sin" 
              width="100%" 
              height="100%" 
              style={{ border: 0 }} 
              allowFullScreen
              className="md:absolute md:inset-0 hidden md:block h-100" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>       
          </div>

          <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
            <p className="leading-relaxed mb-5 text-gray-600">
              We're here to help! If you have any questions or need assistance, please don't hesitate to reach out to us using the form below. Our team is eager to assist you.
            </p>

            <form method="POST" action="">
              <div className="relative mb-4">
                <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => validateName(e.target.value)}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
              </div>

              <div className="relative mb-4">
                <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => validateEmail(e.target.value)}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
              </div>

              <div className="relative mb-4">
                <label htmlFor="message" className="leading-7 text-sm text-gray-600">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={message}
                  onChange={(e) => validateMessage(e.target.value)}
                  className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
                {messageError && <p style={{ color: 'red' }}>{messageError}</p>}
              </div>

              <button
                id="submit-btn"
                className="text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
                type="submit"
                disabled={!isFormValid}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
