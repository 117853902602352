import React from 'react';

const Activities = () => {
  return (
    <main className='text-black container mx-auto'>
      <h1 className="hidden text-4xl text-center">Activities Near Eva Cabins</h1>

      <section className="text-gray-600 body-font my-10 mx-2 md:mx-10">
        <div className="flex flex-col text-center w-full my-2">
          <h2 className="text-2xl md:text-4xl font-medium title-font text-gray-900">Eva Cabins in Dalhousie</h2>
        </div>
        <div className="rounded-lg overflow-hidden mx-auto">
          <img  className="object-cover object-center h-96 w-full" src="/images/dalhousie-himalayas.jpg" alt="Eva Cabins in Dalhousie" />
        </div>
      </section>

      {/* <section className="text-gray-600 body-font mx-2 md:mx-10">
        <div className="container px-5 mx-auto">
          <div className="flex flex-wrap w-full">
            <div className="md:p-4 p-2 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 md:p-8 p-4 flex-col w-full">
                <div className="flex items-start mb-3 w-full">
                  <h2 className="text-gray-900 text-xl title-font font-bold">Scenic Walk to Pukhri Village</h2>
                </div>
                <div className="flex-grow w-full">
                  <p className="leading-relaxed text-base">Take a scenic walk to a small village called Pukhri: 40-minute walk from Deodar Manor. Please ask us for directions. This walk does not need a guide. Excellent for families and small children who can sit on a horseback and go all the way.</p>
                </div>
              </div>
            </div>

            <div className="md:p-4 p-2 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 md:p-8 p-4 flex-col w-full">
                <div className="flex items-start mb-3 w-full">
                  <h2 className="text-gray-900 text-xl title-font font-bold">Meadow Exploration</h2>
                </div>
                <div className="flex-grow w-full">
                  <p className="leading-relaxed text-base">We recommend taking a walk around the meadow early in the morning or late in the evening. Can be enjoyed throughout the day as well. Horseback riding, zorbing for children.</p>
                </div>
              </div>
            </div>

            <div className="md:p-4 p-2 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 md:p-8 p-4 flex-col w-full">
                <div className="flex items-start mb-3 w-full">
                  <h2 className="text-gray-900 text-xl title-font font-bold">Forest Hiking/Trekking</h2>
                </div>
                <div className="flex-grow w-full">
                  <p className="leading-relaxed text-base">Drive up to Jot (15 Km) from Khajjiar and take packed lunch (alloo ka parantha and veggies/pickle). Jot is a scenic spot for a picnic.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="container mx-auto md:p-10 p-5 mx-10">
        <h2 className="text-xl md:text-3xl  font-bold text-center text-gray-800 mb-8">Activities / Things To Do</h2>

        <div className="flex items-center flex-wrap my-3">
          <div className="w-full md:w-1/2 pr-10">
            <h4 className="text-xl md:text-2xl text-gray-800 font-bold mb-3">Bharmour & Kugti</h4>
            <ul className="text-black md:mb-8 mb-1 text-justify">
              <li className="py-2"><span className='font-semibold'>Chaurasi Temple & Bharmani Mata Temple</span>– Visit these ancient temples for spiritual experiences and stunning views.</li>
              <li className="py-2"><span className='font-semibold'>Mani Mahesh & Kugti Pass Treks </span>– Embark on challenging and scenic treks, including the famous Mani Mahesh Lake trek.</li>
              <li className="py-2"><span className='font-semibold'>Kugti Wildlife Sanctuary </span>– Explore the sanctuary, home to Himalayan wildlife like snow leopards and ibex.</li>
              <li className="py-2"><span className='font-semibold'>Local Villages & Culture </span>– Experience traditional Gaddi culture in serene villages like Bharmour and Kugti.</li>
            </ul>
          </div>

          <div className="w-full md:w-1/2 flex justify-between flex-wrap activities_images">
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/eva-cabins-bharmour.png" alt="Chamba" />
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities/Bharmour-Kugti.png" alt="Chamba" />
          </div>
        </div>
        <hr />

        <div className="flex items-center flex-wrap my-3">
          <div className="w-full md:w-1/2 flex flex-wrap justify-between activities_images">
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/eva-cabins-kalatop.png" alt="kalatop-trek" />
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/eva-cabins-kalatop2.png" alt="kalatop-trek" />
          </div>
          <div className="w-full md:w-1/2 md:pl-10 pt-4">
            <h4 className="text-xl md:text-2xl text-gray-800 font-bold mb-3">Kalatop Trek</h4>
            <ul className="text-black md:mb-8 mb-1 text-justify">
              <li className="py-2">Kalatop Trek offers an easy hike through dense forests and the Kalatop Wildlife Sanctuary, with views of the Pir Panjal Range.</li>
            </ul>
          </div>
        </div>
        <hr />

        <div className="flex items-center flex-wrap my-3">
          <div className="w-full md:w-1/2 md:pr-10 pr-2">
            <h4 className="text-xl md:text-2xl text-gray-800 font-bold mb-3">Churah and Pangi valley</h4>
            <ul className="text-black md:mb-8 mb-1 text-justify">
              <li className="py-2"><span className='font-semibold'>Serene Landscapes </span> – Churah is known for its lush green meadows, dense forests, and picturesque villages, offering peace and solitude for nature lovers.</li>
              <li className="py-2"><span className='font-semibold'>Adventure & Trekking </span>– Pangi is a rugged, high-altitude valley popular for adventure sports like trekking, camping, and exploring untouched trails.</li>
            </ul>
          </div>

          <div className="w-full md:w-1/2 flex flex-wrap justify-between activities_images">
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/sach-pass-eva-cabins.png" alt="Bharmour Himachal Pradesh" />
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/sach-pass-eva-cabins2.png" alt="Bharmour Himachal Pradesh" />
          </div>
        </div>
        <hr />

        <div className="flex items-center flex-wrap my-3">
          <div className="w-full md:w-1/2 flex flex-wrap justify-between activities_images">
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities/birdwatching.jpg" alt="Dalhousie Kalatop Main" />
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities/zipline.webp" alt="Kalatop Wildlife Sanctuary" />
          </div>
          <div className="w-full md:w-1/2 md:pl-10 pt-4">
            <h4 className="text-xl md:text-2xl text-gray-800 font-bold mb-3">Birdwatching & Zipline</h4>
            <ul className="text-black md:mb-8 mb-1 text-justify">
              <li className="py-2">Himalayan Birdwatching in Kalatop Wildlife Sanctuary is a delightful experience for nature enthusiasts.</li>
              <li className="py-2">Ziplining in Dalhousie is an exciting adventure activity that offers stunning views of the surrounding hills and valleys.</li>
            </ul>
          </div>
        </div>
        <hr />

      
        <hr />

        <div className="flex items-center flex-wrap my-3">
          <div className="w-full md:w-1/2 md:pr-10 pr-2">
            <h4 className="text-xl md:text-2xl text-gray-800 font-bold mb-3">Enjoy Organic Food and Fruits Session </h4>
            <ul className="text-black md:mb-8 mb-1 text-justify">
              <li className="py-2">Experience the freshness of organic fruits and vegetables as they come into season.</li>
              <li className="py-2">Indulge in a variety of vibrant organic produce during the warm months.</li>
            </ul>
          </div>

          <div className="w-full md:w-1/2 flex flex-wrap justify-between activities_images">
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/eva-cabins-veg.png" alt="Bharmour Himachal Pradesh" />
            <img className="rounded-lg h-48 object-cover md:h-64" src="/images/activities_images/eva-cabins-veg2.png" alt="Bharmour Himachal Pradesh" />
          </div>
        </div>
        <hr />

        
      </section>
    </main>
  );
};

export default Activities;
