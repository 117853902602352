import React from 'react'

const Rules = () => {
  return (
    <>

    <main className='container mx-auto my-4'>
      <h1 className="hidden text-4xl text-center">Eva sunset Cottage & Camp House Rules</h1>
      <h2 className="hidden text-4xl text-center">Rules &amp; Regulation</h2>

      <div
        className="md:mx-10 w-100 md:rounded-lg h-96 flex items-center justify-center text-center bg-cover bg-center"
        style={{ backgroundImage: 'url(images/2024-06-11.jpg)' }}
      ></div>

    <section className="text-black body-font md:mx-10 mx-5">
      


      <ul className="container mx-auto divide-y divide-gray-400 divide-dotted" style={{ fontFamily: 'Raleway' }}>
      <li className="flex items-center justify-between px-4 py-2">
        <div className="antialiased">
          
          <ul className="list-outside text-md  list-disc ml-3 md:ml-6 text-justify"> {/* Added margin-left for better alignment */}
            <li className='mt-4'>Eva Cabins is a no-smoking zone – We kindly ask that all smoking activities take place outside the rooms for the comfort of everyone.
            </li>
            <li className='mt-4'>Alcohol consumption – While you’re welcome to bring your own alcohol to enjoy, we do not serve it on-site. Please note, if intoxicated behavior disrupts other guests or causes property damage, we reserve the right to ask you to leave.
            </li>
            <li className='my-4'>Staff accommodations – We are unable to provide facilities or lodging for drivers, chauffeurs, or household staff. However, we’d be happy to help you find nearby accommodations for them.
            </li>
          </ul>
          
        </div>
        
      </li>

      
    </ul>
    </section>



    </main>
      
    </>
  )
}

export default Rules
