import React from 'react'

const Footer = () => {
  return (
    <>
<footer className="flex flex-col space-y-10 justify-center p-10 bg-black">
      <nav className="flex justify-center flex-wrap gap-6 text-gray-500 font-medium">
        <a className="hover:text-white" href="/about">
          About
        </a>
        <a className="hover:text-white" href="/rooms">
          Rooms
        </a>
        <a className="hover:text-white" href="/activities">
          Activities
        </a>
        <a className="hover:text-white" href="/gallery">
          Gallery
        </a>
        <a className="hover:text-white" href="/rules">
          Rules
        </a>
        <a className="hover:text-white" href="/contact">
          Contact
        </a>
      </nav>

      {/* <div className="flex justify-center space-x-5"> */}
        {/* <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/facebook-new.png" alt="Facebook" />
        </a>
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/linkedin-2.png" alt="LinkedIn" />
        </a> */}
        {/* <a href="https://www.instagram.com/eva_cabins" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/instagram-new.png" alt="Instagram" />
        </a> */}
        {/* <a href="https://messenger.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/facebook-messenger--v2.png" alt="Messenger" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/icons/twitter.png" alt="Twitter" />
        </a> */}
      {/* </div> */}
      
      <p className="text-center text-white font-bold">
         <span className='text-sm'>&copy; 2024  <span className='text-yellow-200'>evacabins.com</span>. All rights reserved.</span>
        <br></br>
        Designed and Developed by<a href="rdevelopers.com" className='hover:text-yellow-600'> rdevelopers.com</a>
      </p>
    </footer>
      
    </>
  )
}

export default Footer
