// src/components/About.js
import React from 'react';

function About() {

  return (
    <>

<div className="px-2 py-5 md:py-20 w-full flex justify-center rounded-lg">
      <div className="bg-white lg:mx-8 lg:flex lg:max-w-5xl lg:shadow-lg rounded-lg">
        <div className="lg:w-1/2">
          <div
            className="lg:scale-110 h-full my-auto bg-cover bg-no-repeat lg:h-full rounded-lg  bg-center "
            style={{ backgroundImage: "url('/images/DJI_0716.png')" }}
          ></div>
        </div>
        <div className="py-12 px-6 lg:px-12 max-w-xl lg:max-w-5xl lg:w-1/2 rounded-t-none border lg:rounded-lg">
          <h2 className="text-3xl text-gray-800 font-bold">
          Eva Cabins
          </h2>
          <p className="mt-4  text-md text-gray-600 text-justify">
          Eva Cabins located in the picturesque hills of Himachal Pradesh, offers a serene escape amidst nature's beauty. With cozy accommodations that blend rustic charm and modern comforts, guests can enjoy stunning sunset views from their private balconies. The camp features various outdoor activities, including hiking, and bonfires, making it an ideal destination for families and friends seeking adventure or relaxation. Each morning, guests can savor a delicious breakfast made from locally sourced ingredients. With attentive service and a warm, welcoming atmosphere, Eva Cabins promises an unforgettable getaway in the heart of the Himalayas.
          </p>
          <div className="mt-8">
            <a href="/booknow" className="bg-gray-900 text-gray-100 px-5 py-3 font-semibold rounded">Book Now</a>
          </div>
        </div>
      </div>
    </div>
     
    </>
  );
}

export default About;
