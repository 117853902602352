// src/App.js
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/imp_com/Navbar';
import styles from "./index.css"
import Footer from './components/imp_com/Footer';
import Activities from './components/Activities';
import Gallery from './components/gallery/Gallery';
import Menu from './components/menu/Menu';
import Rules from './components/Rules/Rules';
import Rooms from './components/Rooms';
import Contact from './components/Contact';
import Booknow from './components/BookNow/Booknow';


function App() {
  return (
    <div>
      <Navbar />

      {/* Define the routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/booknow" element={<Booknow />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
