import React from 'react'

const Cards = () => {
  return (
    <>

<div className="container flex flex-wrap mx-auto my-8">
      
      {/* Card 1 */}
      <div className="w-full sm:w-1/2 md:w-1/2 flex flex-col p-3">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col">
          <div
            className="bg-cover h-96"
            style={{
              backgroundImage: "url(/images/eva-sunset-cabin-camp-room-101.png)"
            }}
          ></div>
          <div className="p-4 flex-1 flex flex-col">
            <h3 className="mb-4 text-2xl">Room 101</h3>
            <div className="mb-4 text-grey-darker text-sm flex-1 text-justify">
              <p>The Mountain View Room is a cozy space that offers breathtaking views of the surrounding hills. This room is ideal for guests who want to wake up to the sight of misty mountains and enjoy the tranquility of nature.</p>
            </div>
            <a href="/booknow" className="border-t font-bold  border-grey-light pt-2 text-md text-grey hover:text-red no-underline tracking-wide">
              Book Now
            </a>
          </div>
        </div>
      </div>

      {/* Card 2 */}
      <div className="w-full sm:w-100 md:w-1/2 flex flex-col p-3 container mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col ">
          <div
            className="bg-cover h-96"
            style={{
              backgroundImage: "url(/images/eva-sunset-cabin-camp61.png)"
            }}
          ></div>
          <div className="p-4 flex-1 flex flex-col">
            <h3 className="mb-4 text-2xl">Room 102</h3>
            <div className="mb-4 text-grey-darker text-sm flex-1 text-justify">
              <p>The Garden View Room offers a refreshing view of the lush gardens surrounding the cottage. With blooming flowers and greenery just outside the window, this room is ideal for those who enjoy the beauty of nature in a serene setting.</p>
            </div>
            <a href="/booknow" className="border-t font-bold  border-grey-light pt-2 text-md text-grey hover:text-red no-underline tracking-wide">
              Book Now 
            </a>
          </div>
        </div>
      </div>

      


    </div>  
      
    </>
  )
}

export default Cards
