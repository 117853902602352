// src/components/About.js
import React from 'react';
import Carousel from '../imp_com/Carousel';

const slides = [
    "/videos/IMG-20241001-WA0003.jpg",
    "/videos/IMG-20241001-WA0001.jpg",
    "/videos/IMG-20241001-WA0005.jpg",
    "/videos/IMG-20241001-WA0006.jpg",
  ]

function About() {
  return <>

    <div className="container  m-auto pt-11">
    <Carousel slides={slides} />
    </div>
  
  
  </>;
}

export default About;
