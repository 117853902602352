import React ,{  useRef , useState  } from 'react'
import emailjs from 'emailjs-com';
import LoadingScreen from '../imp_com/LoadingScreen';


const Booknow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  const formRef = useRef(null); 

  function sendEmail(e) {
    showLoading();
    
    e.preventDefault();
        //This is important, i'm not sure why, but the email won't send without it

    emailjs.sendForm('service_z3jswfj', 'template_k89t2tg', e.target, '0C22TaU2W3mB7tkse')
      .then((result) => {
          hideLoading();
          showPopup() 
          formRef.current.reset();    //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
      }, (error) => {
          console.log(error.text);
      });
  }

  function showPopup() {
    document.getElementById('successPopup').classList.remove('hidden');
  }

  function closePopup() {
      document.getElementById('successPopup').classList.add('hidden');
  }


 
  
  return (



    <>

  	<div>
      {isLoading && <LoadingScreen />}
    </div>




  <div id="successPopup" className="hidden fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
      <h2 className="text-2xl font-bold mb-4">Success!</h2>
      <p>Your email has been sent successfully.</p>
      <button onClick={closePopup}  className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
        Close
      </button>
    </div>
  </div>


    <section className="container p-6 mx-auto  rounded-md shadow-md  my-10">
        <h1 className="text-xl font-bold text-black capitalize dark:text-black">Book Now</h1>
        <form ref={formRef}  className="contact-form" onSubmit={sendEmail}>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div>
              <label className="text-black " htmlFor="username">Name</label>
              <input
                name="username"
                id="name"
                type="text"
                maxlength="75"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                required
              />
            </div>

            <div>
              <label className="text-black " htmlFor="emailAddress">Email Address</label>
              <input
                name="emailAddress"
                id="emailAddress"
                type="email"
                maxlength="75"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                required
              />
            </div>

            <div>
              <label className="text-black" htmlFor="PhoneNumber">Phone Number</label>
              <input
                name="PhoneNumber"
                id="PhoneNumber"
                type="text" pattern="\d*" maxlength="14"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                required
              />
            </div>

            <div>
              <label className="text-black" htmlFor="date">Book Date</label>
              <input
              name="dateOfBooking"
                id="date"
                type="date"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                required
              />
            </div>

            <div>
              <label className="text-black" htmlFor="select">Select</label>
              <select name="type_of_room"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              >
                <option className='text'>Room1 (₹1500)</option>
                <option>Room2 (₹1500)</option>
              </select>
            </div>


            {/* <div>
              <label className="text-black" htmlFor="textarea">Text Area</label>
              <textarea
                id="textarea"
                className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md   focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
              ></textarea>
            </div> */}

            {/* <div>
              <label className="block text-sm font-medium text-black">Image</label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-black" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                      <span>Upload a file</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1 text-black">or drag and drop</p>
                  </div>
                  <p className="text-xs text-black">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
            </div> */}
          </div>

          <div className="flex justify-end mt-6">
            <button className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600">
              Send
            </button>
          </div>


        </form>
      </section>

     





      
    </>
  )
}

export default Booknow
